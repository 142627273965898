import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/domain/[domain]/components/aboutus.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/domain/[domain]/components/contactus.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/domain/[domain]/components/hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/domain/[domain]/components/productcarosell.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/vercel/path0/app/domain/[domain]/components/seperator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/domain/[domain]/components/testamonials/Testimonial Caroslal.tsx");
